@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'





































































































































































































































































































































































































.v-data-table::v-deep
  tbody > tr > td, thead > tr > th
    padding-right: 0 !important
    padding-left: 10px !important
