@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'



































































































































































































































































































.v-text-field--text-right::v-deep
  input
    text-align: right

.v-data-table::v-deep
  tbody > tr > td, thead > tr > th
    padding-right: 0 !important
    padding-left: 10px !important

    &:last-child
      padding-right: 10px !important
